@import "./theme/colors";

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  background: $secondary-core;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

#mask {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;

  left: 0;
  top: 0;
  position: fixed;
  will-change: transform;
  transition: opacity 0.25s;

  z-index: 100;
}

div[tabindex][role="group"] {
  flex: 1;
  display: flex;
  height: 100%;

  overflow-y: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  border-radius: 2px;
  -webkit-text-fill-color: $primary-opaque;
  -webkit-box-shadow: 0 0 0 1000px $secondary-super-dark inset;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: $on-secondary;
}
