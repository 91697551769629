@import "../../theme/theme";

.delete-item__confirmation-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 32px;

  font-size: 16px;

  line-height: 32px;
}

.delete-item__warning-text {
  color: $super-highlight;
  font-weight: 600;
}

.delete-item__item-box {
  display: flex;
  flex-direction: column;

  margin: 48px 0;

  background-color: $secondary-dark;

  border-radius: 3px;
}

.delete-item__item-image {
  height: 200px;
  width: 200px;
}

.delete-item__item-info {
  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;

  font-size: 14px;
}

.delete-item__item-name {
  margin: 12px 0 4px;
  font-weight: 600;
  text-overflow: ellipsis;
  line-height: 20px;
  letter-spacing: 0.015em;
}

.delete-item__item-description {
  opacity: 0.6;
  text-overflow: ellipsis;
  overflow: hidden;
}

.delete-item__options {
  display: flex;
  margin: 16px 0;
}
