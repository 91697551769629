@import "../../theme/theme";

#nav-bar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  height: 100%;
  min-width: 230px;
  max-width: 320px;
  width: 230px;

  margin: 0;
  padding: 24px 0 0 0;

  background: $secondary-dark;

  font-family: $core-font;
  font-size: 14px;
  font-weight: 600;

  z-index: 9999;
  user-select: none;
  cursor: default;
}

.nav-bar__header {
  display: flex;
  justify-content: center;

  height: 60px;
  width: 100%;

  font-family: $logo-font;
  font-size: 24px;
  color: $on-secondary;
}

.nav-bar__menu {
  display: flex;
  flex-direction: column;

  width: 100%;

  user-select: none;
}

.nav-bar__menu-Item {
  width: 100%;
  line-height: 20px;
  letter-spacing: 0.015em;
}

.nav-bar__menu-link-wrapper {
  position: relative;
  display: flex;
  color: inherit;
  text-decoration: none;
}

.nav-bar__menu-link {
  position: relative;
  display: flex;
  align-items: center;

  height: 40px;
  width: 100%;
  padding: 0 24px;

  //color: #b3b3b3;
}

.nav-bar__menu-link--active {
  color: $on-secondary;
}

.nav-bar__menu-link--active:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  bottom: 5px;
  width: 4px;
  background: $highlight;
}

.nav-bar__menu-link-icon {
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px;
}

.nav-bar__menu-link-label {
  margin-left: 16px;
}

.effect__link-fade:not(.nav-bar__menu-link--active) {
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  color: #b3b3b3;
}

.effect__link-fade:hover,
.effect__link-fade:focus {
  color: $on-secondary;
}

.nav-bar__list {
  flex: 1;
  color: $on-secondary;
}

.nav-bar__footer {
  display: flex;
  flex-direction: column;

  width: 100%;

  color: $on-secondary;
}

.nav-bar__user-info {
  display: flex;
  margin: 8px 24px 24px 24px;
  padding-top: 10px;
  border-top: 1px solid $secondary-light;
}

.nav-bar__user-info-link {
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;

  cursor: pointer;
}

.nav-bar__user-info-link--active {
  color: $highlight;
}

.nav-bar__user-info-avatar {
  display: flex;
  align-items: center;
  height: 28px;
  width: 28px;
}

.nav-bar__user-info-name {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
