@import "../../theme/theme";

.data-field {
  display: flex;
  flex-direction: column;

  width: 100%;

  margin-bottom: 16px;
}


.data-field__label {
  font-weight: 600;
}

.data-field__value {
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;

  margin: 12px 0;
  //margin-top: 0.7em;
  padding: 0 0.75em;

  background: $secondary-super-dark;

  color: $primary-opaque;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;

  outline: none;
  border: 0;
  border-radius: 2px;

  font-family: $core-font;
}





