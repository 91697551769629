$primary: #ffffff;
$primary-light: #ffffff;
$primary-dark: #cccccc;
$primary-opaque: #e3e3e8;

$secondary: #1b2b3b;
$secondary-light: #546172;
$secondary-dark: #011120;
$secondary-super-dark: #040b12;
$secondary-core: #001c38;

$on-primary: #121212;
$on-primary-light: #a1a1a1;
$on-secondary: #fff;

$highlight: #fa4141;
$super-highlight: #ff5454;
$highlight-2: #7755cc;
$highlight-3: #16abae;
$super-highlight-3: #39f7fa;
//$highlight-4: #10d463;
$highlight-4: #45cb85;
