@import "../../theme/theme";

.toggle__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.toggle__container {
  position: relative;

  width: 70px;
  height: 40px;

  background-color: $highlight-4;

  border-radius: 32px;

  cursor: pointer;
  user-select: none;

  transition: all 0.2s ease;

  top: 0;
  bottom: 0;
}

.toggle__button {
  position: absolute;
  left: 35px;
  top: 5px;

  width: 30px;
  height: 30px;
  border-radius: 32px;

  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

  cursor: pointer;

  transition: all 0.2s ease;
}

.toggle__container--disabled {
  background-color: #5a5a66;
}

.toggle__button--disabled {
  left: 5px;
  background-color: #eceef0;
}
