@import "../../theme/theme";

.loader {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  color: $on-secondary;
  font-size: 90px;

  z-index: 9999;
}
