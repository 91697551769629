@import "../../theme/theme";

.form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.form-field__row {
  display: flex;
  flex-direction: row;
  margin-top: 0.7em;
  margin-bottom: 1em;
}

.form-field__label {
  font-weight: bold;
}

.form-field__label--required {
  color: $highlight;
}

.form-field__input {
  height: 48px;
  width: 100%;

  margin: 12px 0;
  //margin-top: 0.7em;
  padding: 0 0.75em;

  //background: $secondary-dark;
  background: $secondary-super-dark;
  // 4F4FD6
  // 2169B0
  // F564A9
  // 119DA4
  // F05D23 FLAME

  color: $primary-opaque;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;

  outline: none;
  border: 0;
  border-radius: 2px;

  font-family: $core-font;
}

.form-field__checkbox {
  margin-top: 0;
  margin-left: 1.5em;
}

.form-field__textarea {
  //min-width: 800px;
  //max-width: 800px;
  min-height: 100px;
  padding: 0.75em;
  line-height: 1.5em;
}

.form-field__error {
  margin-left: 15px;
  color: $super-highlight;
  font-weight: bold;
  letter-spacing: 0.05em;
}
