@import "../../theme/theme";

.demo-features__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  //max-width: 700px;
}

.demo-feature {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;

  width: 100%;
}

.demo-feature__label {
  font-size: 24px;
}

.demo-feature__label--disabled {
  opacity: 0.5;
}
