@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda:400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda:400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda:400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda:400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda:400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda:400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda:400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda:400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda:400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda:400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda:400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda:400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda:400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono|Ruda:400,600,700,800);
* {
  box-sizing: border-box; }

html,
body {
  height: 100%;
  width: 100%;
  background: #001c38; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; }

#mask {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  position: fixed;
  will-change: transform;
  transition: opacity 0.25s;
  z-index: 100; }

div[tabindex][role="group"] {
  flex: 1 1;
  display: flex;
  height: 100%;
  overflow-y: auto; }

a {
  color: inherit;
  text-decoration: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  border-radius: 2px;
  -webkit-text-fill-color: #e3e3e8;
  -webkit-box-shadow: 0 0 0 1000px #040b12 inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: #fff; }

.content {
  flex: 1 1;
  display: flex;
  flex-direction: column; }

.content__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }

.content__header-title {
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.005em;
  font-weight: 600;
  margin: 24px 0;
  border-bottom: 2px solid #fa4141; }

.content__header-action {
  display: flex;
  align-items: center;
  min-height: 30px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.effect__link-fade-opacity {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  opacity: 0.6; }

.effect__link-fade-opacity:hover,
.effect__link-fade-opacity:focus {
  opacity: 1; }

.content__body {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 8px; }

#view {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 32px;
  margin: 0 auto;
  max-width: 1480px;
  z-index: 200;
  font-family: "Ruda", sans-serif;
  font-size: 14px;
  color: #fff;
  overflow-y: scroll; }

.view__not-found--image {
  width: 450px;
  margin: 15px 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 8px;
  border-radius: 10px; }

.grid-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  min-width: 150px;
  max-width: 300px;
  padding: 0 8px 2.5em;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.grid-item__image-frame {
  position: relative;
  width: 100%;
  background: #040b12;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  cursor: pointer; }

.grid-item__image-frame:after {
  content: "";
  display: block;
  padding-bottom: 100%; }

.grid-item__image-frame-placeholder {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 75px; }

.grid-item__link {
  display: flex;
  flex-direction: column;
  width: 100%; }

.grid-item__image-frame-content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%; }

.grid-item__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer; }

.grid-item__name {
  margin: 12px 0 4px;
  font-weight: 600;
  text-overflow: ellipsis;
  line-height: 20px;
  letter-spacing: 0.015em; }

.grid-item__description {
  opacity: 0.6;
  text-overflow: ellipsis;
  overflow: hidden; }

.effect__filter-darken {
  -webkit-filter: brightness(1);
          filter: brightness(1); }

.effect__filter-darken:hover > .grid-item__image-frame,
.effect__filter-darken:focus > .grid-item__image-frame {
  transition-property: filter, -webkit-filter;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.3, 0, 0, 1);
  -webkit-filter: brightness(0.3);
          filter: brightness(0.3); }

.grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 15px; }

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin-top: 16px;
  margin-bottom: 0;
  background: rgba(0, 28, 56, 0.7);
  font-family: "Ruda", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  line-height: 1.6;
  border: 0;
  border-radius: 500px;
  box-shadow: inset 0 0 0 2px #b3b3b3;
  will-change: transform;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.button--small {
  min-width: 240px;
  padding: 7px 22px; }

.button--xsmall {
  min-width: 140px;
  padding: 7px 22px; }

.effect__grow {
  transition: none 33ms cubic-bezier(0.3, 0, 0.7, 1);
  transition-property: all; }

.effect__grow:hover,
.effect__grow:focus {
  box-shadow: inset 0 0 0 2px #fff, 0 0 0 1px transparent;
  transform: scale(1.06); }

.error-box__container {
  margin: 16px;
  padding: 16px;
  border-radius: 2px;
  background-color: #ffffff;
  color: #121212;
  font-weight: 500;
  font-size: 16px; }

.error-box__icon {
  margin-left: 16px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #fa4141; }

.form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px; }

.form-field__row {
  display: flex;
  flex-direction: row;
  margin-top: 0.7em;
  margin-bottom: 1em; }

.form-field__label {
  font-weight: bold; }

.form-field__label--required {
  color: #fa4141; }

.form-field__input {
  height: 48px;
  width: 100%;
  margin: 12px 0;
  padding: 0 0.75em;
  background: #040b12;
  color: #e3e3e8;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
  outline: none;
  border: 0;
  border-radius: 2px;
  font-family: "Ruda", sans-serif; }

.form-field__checkbox {
  margin-top: 0;
  margin-left: 1.5em; }

.form-field__textarea {
  min-height: 100px;
  padding: 0.75em;
  line-height: 1.5em; }

.form-field__error {
  margin-left: 15px;
  color: #ff5454;
  font-weight: bold;
  letter-spacing: 0.05em; }

.pill-button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  min-width: 160px;
  max-width: 320px;
  margin: 0.7em 16px 1em;
  padding: 10px 0;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  font-weight: 800;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  border-radius: 500px;
  border: 0;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.effect__bg-fade {
  background: #fa4141;
  transition-property: background-color, border-color, color, box-shadow, filter, -webkit-filter;
  transition-duration: 0.3s;
  letter-spacing: 2px;
  white-space: normal; }

.effect__bg-fade:hover {
  background-color: #ff5c5c; }

.pill-button--disabled {
  background: #1b2b3b;
  opacity: 0.5; }

.form {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100%; }

.demo-features__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%; }

.demo-feature {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  width: 100%; }

.demo-feature__label {
  font-size: 24px; }

.demo-feature__label--disabled {
  opacity: 0.5; }

.settings_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center; }

.data-field {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px; }

.data-field__label {
  font-weight: 600; }

.data-field__value {
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  margin: 12px 0;
  padding: 0 0.75em;
  background: #040b12;
  color: #e3e3e8;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
  outline: none;
  border: 0;
  border-radius: 2px;
  font-family: "Ruda", sans-serif; }

.user-profile__avatar {
  height: 150px;
  width: 150px;
  margin: 0 0 24px 0;
  border-radius: 50%; }

#nav-bar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  min-width: 230px;
  max-width: 320px;
  width: 230px;
  margin: 0;
  padding: 24px 0 0 0;
  background: #011120;
  font-family: "Ruda", sans-serif;
  font-size: 14px;
  font-weight: 600;
  z-index: 9999;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: default; }

.nav-bar__header {
  display: flex;
  justify-content: center;
  height: 60px;
  width: 100%;
  font-family: "Share Tech Mono", monospace;
  font-size: 24px;
  color: #fff; }

.nav-bar__menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.nav-bar__menu-Item {
  width: 100%;
  line-height: 20px;
  letter-spacing: 0.015em; }

.nav-bar__menu-link-wrapper {
  position: relative;
  display: flex;
  color: inherit;
  text-decoration: none; }

.nav-bar__menu-link {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0 24px; }

.nav-bar__menu-link--active {
  color: #fff; }

.nav-bar__menu-link--active:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  bottom: 5px;
  width: 4px;
  background: #fa4141; }

.nav-bar__menu-link-icon {
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px; }

.nav-bar__menu-link-label {
  margin-left: 16px; }

.effect__link-fade:not(.nav-bar__menu-link--active) {
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  color: #b3b3b3; }

.effect__link-fade:hover,
.effect__link-fade:focus {
  color: #fff; }

.nav-bar__list {
  flex: 1 1;
  color: #fff; }

.nav-bar__footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #fff; }

.nav-bar__user-info {
  display: flex;
  margin: 8px 24px 24px 24px;
  padding-top: 10px;
  border-top: 1px solid #546172; }

.nav-bar__user-info-link {
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  cursor: pointer; }

.nav-bar__user-info-link--active {
  color: #fa4141; }

.nav-bar__user-info-avatar {
  display: flex;
  align-items: center;
  height: 28px;
  width: 28px; }

.nav-bar__user-info-name {
  display: flex;
  align-items: center;
  margin-left: 10px; }

.menu-item {
  display: flex;
  flex-direction: column; }

.menu-item__image {
  width: 450px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 8px;
  border-radius: 10px; }

.menu-item__price {
  font-size: 32px;
  font-weight: 900;
  direction: ltr;
  margin: 20px 0; }

.menu-item__details {
  padding: 30px 0;
  margin-bottom: 15px;
  font-size: 16px;
  border-top: 1px solid #1b2b3b;
  border-bottom: 1px solid #1b2b3b; }

.menu-item__actions {
  display: flex;
  justify-content: space-around;
  width: 100%; }

.app {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: hidden; }

.delete-item__confirmation-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  font-size: 16px;
  line-height: 32px; }

.delete-item__warning-text {
  color: #ff5454;
  font-weight: 600; }

.delete-item__item-box {
  display: flex;
  flex-direction: column;
  margin: 48px 0;
  background-color: #011120;
  border-radius: 3px; }

.delete-item__item-image {
  height: 200px;
  width: 200px; }

.delete-item__item-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-size: 14px; }

.delete-item__item-name {
  margin: 12px 0 4px;
  font-weight: 600;
  text-overflow: ellipsis;
  line-height: 20px;
  letter-spacing: 0.015em; }

.delete-item__item-description {
  opacity: 0.6;
  text-overflow: ellipsis;
  overflow: hidden; }

.delete-item__options {
  display: flex;
  margin: 16px 0; }

.loader {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 90px;
  z-index: 9999; }

.toggle__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.toggle__container {
  position: relative;
  width: 70px;
  height: 40px;
  background-color: #45cb85;
  border-radius: 32px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: all 0.2s ease;
  top: 0;
  bottom: 0; }

.toggle__button {
  position: absolute;
  left: 35px;
  top: 5px;
  width: 30px;
  height: 30px;
  border-radius: 32px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.2s ease; }

.toggle__container--disabled {
  background-color: #5a5a66; }

.toggle__button--disabled {
  left: 5px;
  background-color: #eceef0; }

