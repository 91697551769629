@import "../../theme/theme";


.pill-button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  min-width: 160px;
  max-width: 320px;
  margin: 0.7em 16px 1em;
  padding: 10px 0;

  font-family: $core-font;
  font-size: 13px;
  font-weight: 800;
  line-height: 1;
  color: $on-secondary;
  text-transform: uppercase;
  letter-spacing: 0.16em;

  border-radius: 500px;
  border: 0;
  outline: none;

  cursor: pointer;
  user-select: none;
}

.effect__bg-fade {
  background: $highlight;
  transition-property: background-color, border-color, color, box-shadow, filter,
    -webkit-box-shadow, -webkit-filter;
  transition-duration: 0.3s;
  letter-spacing: 2px;

  white-space: normal;
}

.effect__bg-fade:hover {
  background-color: #ff5c5c;
}

.pill-button--disabled {
  background: $secondary;
  opacity: 0.5;
}
