@import "../../theme/colors";

.form {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100%;
  //max-width: 600px;
}



