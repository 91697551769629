@import "../../theme/theme";

#view {
  flex: 1;
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 0 32px;
  margin: 0 auto;
  max-width: 1480px;

  z-index: 200;

  font-family: $core-font;
  font-size: 14px;
  color: $on-secondary;

  overflow-y: scroll;
}

.view__not-found--image {
  width: 450px;
  margin: 15px 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 8px;
  border-radius: 10px;
}
