@import "../../theme/theme";

.error-box__container {
  margin: 16px;
  padding: 16px;

  border-radius: 2px;

  background-color: $primary;
  color: $on-primary;
  font-weight: 500;
  font-size: 16px;
}

.error-box__icon {
  margin-left: 16px;
  cursor: pointer;
  user-select: none;

  color: $highlight;
}
